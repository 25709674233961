import React from "react";

import Layout from "../../components/Layout";
import AboutSection from "../../content/AboutSection";

const About = (props) => {
  return (
    <Layout>
      <AboutSection hash={props.location.hash} isPage />
    </Layout>
  );
};

export default About;
